app.config(['$translateProvider', function ($translateProvider) {
$translateProvider.translations('th', {
	RM_applyDate:"วันที่สมัคร",
	RM_honor:"เกียรตินิยม",
	Open_org_Export:"ประเภทการส่งออก",
	RMProbation_agree:"ตกลง",
	probation:"Probation",
	RMProbationHistory_Em_ID:"รหัสพนักงาน",
	RM_phoneNumber:"เบอร์โทรศัพท์บ้าน",
	attach_file:"Attach File",
	annual:"Annual",
	tel:"Tel",
	RM_weight:"น้ำหนัก",
	Manage_Org_R2_NLC:"รหัสระดับหน่วยงาน",
	career_curLevel:"ตำแหน่งปัจจุบัน",
	RM_otherSkill:"ทักษะอื่นๆ",
	RM_monthlySalary:"เงินเดือน",
	ApplicationBackend:"ส่วนการทำงานแบล็คเอ็น",
	Open_org_Travel:"เดินทางต่างจังหวัด",
	navbar_gsb01_inbox:"กล่องข้อความ",
	personal:"Personal",
	RM_hrAssistant_hrOfficer:"ผู้ช่วยทรัพยากรบุคคล",
	RM004_06_evaluate_comment:"ความคิดเห็น",
	RM_careerDetail:"อาชีพงาน (Career) งานทุกงานที่พนักงานได้มีประสบการณ์มาตลอดการทำงานของแต่ละบุคคลพนักงานที่จะประสบความสำเร็จต้องกำหนดเป้าหมาย วางแผนและดำเนินการ เพื่อให้ได้มีประสบการณ์ในงานด้านต่าง ๆ จนถึงจุดมุ่งหมายในงานและตำแหน่งที่ตั้งไว้ ",
	RM004_06_user_info_assesor:"ผู้อนุมัติ",
	morning:"Morning",
	RM_clusterOfRelate:"กลุ่มอาชีพหรือหน้าที่ ที่เกี่ยวข้อง ",
	RM_institute:"ชื่อสถาบันการศึกษา",
	RMProbationHistory_No:"ครั้งที่",
	overtime:"Overtime",
	RM:"dvgsg",
	RM_careerPath:"เส้นทางอาชีพ",
	RM_attachDate:"วันที่แนบไฟล์",
	RM_fatherName:"ชื่อ-นามสกุลบิดา",
	RM_tel:"เบอร์ติดต่อ",
	RM_occupation:"สถานประกอบอาชีพ",
	RM_occupationType:"ประเภทอาชีพ",
	activity:"Activity",
	without_pay:"Without Pay",
	RM_floor:"ชั้น",
	Open_org_mael:"ชาย",
	ApplicationProbationEva:"ประเมินทดลองผ่านงาน",
	RM_language:"ภาษา",
	RM_function:"การทำงาน",
	RM_date_of_birth:"วัน/เดือน/ปี เกิด",
	RM_country:"ประเทศ",
	RM_country_of_birth:"ประเทศที่เกิด",
	RM_delete:"ลบรายการ",
	RM004_06_evaluate_submit:"ยืนยัน",
	RM_to:"ถึง",
	RM_computerSkill:"ทักษะทางคอมพิวเตอร์",
	RM004_06_user_info_department:"สังกัด/ฝ่าย",
	ess_no:"ไม่ใช่",
	RMProbationHistory_Department:"สังกัด/ฝ่าย",
	RM004_06_evaluate_probationEvaluation:"ประเมินการผ่านงาน",
	benefit_leaveTpye:"ประเภทการลา",
	career_supervisor:"หัวหน้างาน",
	RM_remark:"สังเกต",
	RM_jobDescription1:"ลักษณะงานในตำแหน่งที่ 1",
	RM_jobDescription2:"ลักษณะงานในตำแหน่งที่ 2",
	RM_street:"ถนน",
	RM004_06_evaluate_submitAnAssessment:"ส่งแบบประเมิน",
	RM_cancel:"ยกเลิก",
	RM_submit:"ยืนยัน",
	RM004_07_probation:"ประเมินทดลองผ่านงาน",
	idp_kpi:"IDP/KPI",
	RM_jobDescription3:"ลักษณะงานในตำแหน่งที่ 3",
	RM_lastName:"นามสกุล",
	RM_jobDescription:"ลักษณะงาน",
	RM_internet:"อินเตอร์เน็ต",
	Open_org_Cost_center:"ศูนย์ต้นทุนที่จะย้ายไป",
	Open_org_vacancy_type:"ประเภทตำแหน่งงานว่าง",
	RM_jobFamilyDetail:"การจัดกลุ่มลักษณะงาน (Job Family) คือ การรวบรวมงานที่มีลักษณะคล้ายคลึงกันเข้าเป็นกลุ่ม (cluster of relatedoccupations or functions) โดยเน้นที่สมรรถนะความสามารถ (competencies) อันได้แก่ความรู้ (knowledge), ทักษะ (skills)",
	Manage_Org_R2_ION:"หน่วยงานต้นสังกัด",
	RM_responsibility:"ลักษณะงาน",
	RM_career_path:"เส้นทางความก้าวหน้าในสายอาชีพ (Career Path) เส้นทางความก้าวหน้าในการทำงานที่พนักงานจะสามารถก้าวหน้าได้ในสายอาชีพตามเกณฑ์ที่ธนาคารกำหนด",
	RM_disagree:"ไม่เห็นด้วย",
	org_sub_func:"ฟังก์ชันงานรอง",
	RM_hrBusinessPartner:"พันธมิตรทางธุรกิจด้านทรัพยากรบุคคล",
	reason_of_leave:"Reason of Leave",
	RM_career:"อาชีพ",
	Manage_Org_R2_NewOrg:"สร้างกล่องหน่วยงานใหม่",
	Open_org_Em_type:"ประเภทพนักงาน",
	RM_trainingProgram:"หลักสูตรการอบรม",
	RM_status:"สถานะของรายการใบสมัคร",
	leave:"Leave",
	RM_hrCoordinator:"ประสานงานทรัพยากรบุคคล",
	Open_org_sub_func:"ฟังก์ชันงานรอง",
	RM_upload:"อัปโหลด",
	RM_position_applied_for1:"สมัครงานในตำแหน่งที่ 1",
	RM_position_applied_for2:"สมัครงานในตำแหน่งที่ 2",
	RM_position_applied_for3:"สมัครงานในตำแหน่งที่ 3",
	RM002_01_job_detail_Experience:"ประสบการณ์ทำงาน",
	RM_applicationForm:"ใบสมัคร",
	RM_id_card_for_FWD:"หมายเลขประจำตัวคนพิการ",
	RM_joinUs:"ร่วมงานกับเรา",
	RM_attributes:"คุณลักษณะ",
	RM_age:"อายุ",
	Open_org_Qrganize:"องค์กร/หน่วยงาน",
	RM_skills:"ทักษะ",
	RMProbation_popup_success_completed:" เรียบร้อยแล้ว",
	RM_village:"หมู่บ้าน",
	Open_org_Reason:"เหตุผล",
	Manage_Org_R2_location:"สถานที่",
	full_day:"Full Day",
	RM_workGoals:"เป้าหมายในการทำงาน",
	ess_yes:"ใช่",
	leave_request:"Leave Request",
	RM_careerMangement:"การจัดการอาชีพ",
	RM_prefix:"คำนำหน้า",
	RM_maritalStatus:"สถานภาพ",
	RMProbation_confirm:"ยืนยัน",
	RM004_08_assessment_pass:"ผ่าน",
	RMProbationHistory_Name:"ชื่อ - นามสกุล พนักงาน",
	RM_view:"ดูข้อมูล",
	Open_org_Req_date:"วันที่ร้องขอ",
	Open_org_Em_group:"กลุ่มพนักงาน",
	training:"Training",
	RM_personalInFormation:"ข้อมูลส่วนบุคคล",
	RM_room:"ห้อง",
	Open_org_Job_func:"ฟังก์ชันงาน",
	RM_development:"การพัฒนา",
	RM_educationBackground:"ประวัติการศึกษา",
	RMProbationHistory_Position:"ตำแหน่ง",
	Open_org_Pos_leval:"ระดับตำแหน่ง",
	RM_workExperience:"ประวัติการทำงาน",
	Open_org_CR:"สร้างใบคำขออัตรากำลัง ",
	navbar_gsb01_lang:"TH",
	Open_org_Special:"คำขอพิเศษ",
	modalPositionAdd_PB:"ชื่อตำแหน่ง",
	RM_gender:"เพศ",
	RM_profileImage:"รูปโปรไฟล์",
	RM_talent:"ความสามารถพิเศษ",
	ticket:"Ticket",
	RM_district:"อำเภอ/เขต",
	Open_org_PBC:"รหัสกล่องตำแหน่ง",
	Open_org_femael:"หญิง",
	RM_height:"ส่วนสูง",
	RM_careerManageDetail:"การจัดการสายอาชีพของพนักงาน (Career Management) เป็นระบบ/กระบวนการ/แผนงานต่าง ๆ ที่ธนาคารจัดเตรียมขึ้นผ่านระบบการจัดการทรัพยากรบุคคล เพื่อส่งเสริมการพัฒนาสายอาชีพของพนักงาน",
	Open_org_move_position:"ย้ายกล่องตำแหน่งงาน",
	RM004_06_user_info_positionTitle:"ตำแหน่ง",
	RM004_06_user_info_nameLastName:"ชื่อ-สกุล",
	Open_org_local_job:"ระดับขั้นของงาน",
	Open_org_gender:"เพศ",
	RM_successfulWork:"ผลงานที่ประสบความสำเร็จ",
	RM_couseName:"รายชื่อหลักสูตร",
	RM_motherName:"ชื่อ-นามสกุลมารดา",
	Open_org_Name:"ชื่อ",
	RM_emailAddress:"อีเมล",
	RM_id_card_number:"เลขที่บัตรประชาชน",
	RM004_08_assessment_probation:"ประเมิน",
	RM_submitApplication:"ส่งใบสมัคร",
	RM004_08_assessment_days:"วัน",
	RMProbation_popup_success_to:"ของ ",
	RM_edit:"แก้ไขข้อมูล",
	delegate_work_to:"Delegate Work to",
	RM004_06_attach_file_label:"ไฟล์แนบ",
	Manage_Org_R2_ED:"วันที่มีผล",
	RM_vision:"วิสัยทัศน์",
	RM_fullName_eng:"ชื่อ",
	requisition:"Requisition",
	RM_finish:"สำเร็จ",
	sick:"Sick",
	resignReq_no:"ลำดับที่",
	RM_languageSkill:"ทักษะภาษา",
	ApplicationManageOrg:"จัดการผังองค์กร",
	RM004_06_evaluate_overallComment:"แสดงความคิดเห็น",
	RM_pleaseSelect:"โปรดเลือก",
	Open_org_Abroad:"เดินทางต่างประเทศ",
	resignReq_resignDate1:"วันที่มีผลการลาออก ",
	RM_subDistrict:"ตำบล/แขวง",
	RM_level:"ระดับ",
	RM_major:"สาขา",
	RM_verifyCode:"ตรวจสอบรหัส",
	RM_jobApplicationDate:"วันที่ทำการสมัคร",
	Open_org_move_to:"ย้ายไป",
	Open_org_Budget:"งบประมาณ",
	RM004_06_user_info_employeeID:"เลขประจำตัว",
	RM002_01_job_detail_Other:"รายละเอียดอื่นๆ",
	RM_degree:"วุฒิการศึกษา",
	RM_printAdvertisement:"สื่อสิ่งพิมพ์",
	RM_job_deteil_major:"สาขา",
	Open_org_pos_box_type:"ประเภทกล่องตำแหน่ง",
	RM_relationShip:"ความสัมพันธ์",
	RM_Salary:"เงินเดือน",
	RM004_06_user_info_days:"วัน",
	employee_name_sub:"ชื่อ-นามสกุล",
	RM_id:"ไอดีเลขใบสมัคร",
	RM004_06_user_info_period:"ครั้งที่",
	RMProbation_close:"ปิด",
	other:"Other",
	RM004_08_assessment_notPass:"ไม่ผ่าน",
	go_abroad:"Go Abroad",
	RM_track_of_disabilities:"ประเภทความพิการ",
	RM004_06_user_info_hiringDate:"วันที่เริ่มต้น",
	Open_org_Active:"สถานะพร้อมใช้งาน",
	save:"Save",
	RM_fileName:"ชื่อไฟล์",
	RMProbationHistory_Eva:"วันที่ประเมิน",
	RM_vicePresident_of_hr:"รองประธานด้านทรัพยากรบุคคล",
	RM004_08_assessment_extend:"ขยายเวลา",
	RM_building:"ชื่ออาคาร/ตึก",
	delegate:"Delegate",
	RM_nationality:"สัญชาติ",
	RM_otherInformation:"ข้อมูลเพิ่มเติม",
	resignReq_reOfResign:"เหตุผลการลาออก",
	RM_province:"จังหวัด",
	RM_headhunterRecruitAgency:"บริษัทจัดหางาน",
	RM002_01_job_detail_Educational:"วุฒิการศึกษา",
	RM_utlize:"ใช้ประโยชน์",
	modalPositionAdd_NPB:"สร้างกล่องตำแหน่งใหม่",
	RM_driving_license_type:"ประเภทใบอนุญาตขับขี่",
	Manage_Org_R2_Dept:"รหัสแผนก",
	Manage_Org_R2_Node_Type:"ประเภทกล่องหน่วยงาน",
	urgent:"Urgent",
	half_day:"Half Day",
	RM_moo:"หมู่",
	RM_educationLevel:"ระดับการศึกษา",
	ApplicationApp:"แอพพลิเคชั่น",
	RM_apply:"สมัครงาน",
	Manage_Org_R2_count:"นับจำนวน",
	resignReq_exitlnter:"แบบสอบถามการลาออก",
	RM004_06_expected_product_overallComment:"แสดงความคิดเห็น",
	RM_zipCode:"รหัสไปรษณีย์",
	RM_jobFamily:"กลุ่มลักษณะงาน",
	Manage_Org_R2_ParentOrg:"หน่วยงานต้นสังกัดโดยตรง",
	RM004_06_attach_file_drag_drop:"ลากและวางที่นี่",
	inbox:"Inbox",
	date:"Date",
	resignReq_reqDate1:"วันที่ทำรายการ",
	RM_sector_of_position1:"ภาคในตำแหน่งที่ 1",
	RM_sector_of_position3:"ภาคในตำแหน่งที่ 3",
	RM_sector_of_position2:"ภาคในตำแหน่งที่ 2",
	Open_org_Request:"ขอโดย",
	RM_personalDataProtect:"นโยบายคุ้มครองข้อมูลส่วนบุคคล",
	resignReq_status:"สถานะการอนุมัติ",
	RM_essence:"สาระสำคัญ",
	RM_grade:"เกรดเฉลี่ย",
	RM_qualification:"คุณสมบัติ",
	RM_weakness:"จุดอ่อน",
	RM_attachType:"ชนิดไฟล์แนบ",
	Manage_Org_R2_cost:"ศูนย์ต้นทุน",
	RM_save:"บันทึก",
	RM_strengths:"จุดแข็ง",
	resignReq_rejectReason:"สาเหตุการไม่อนุมัติ",
	RM_information:"ประชาสัมพันธ์",
	RM_from:"เริ่มจาก",
	RM004_06_user_info_passingDate:"วันที่ครบกำหนด",
	RM_which_channel_dyk:"ท่านทราบข่าวรับสมัครจากที่ใด",
	workflow:"Workflow",
	RM_musicSkill:"ทักษะทางดนตรี",
	RM_companyName:"ชื่อบริษัท",
	expense:"Expense",
	Open_org_report:"ประเภทรายงาน",
	RM_back_to_home:"กลับไปหน้าแรก",
	RM_address:"ที่อยู่",
	hourly:"Hourly",
	request:"Request",
	RM_militaryStatus:"สถานภาพทางการทหาร",
	RM_other:"อื่นๆ",
	Manage_Org_R2_NED:"Node Effective Date",
	RM_humanResource:" ทรัพยากรบุคคล",
	RM_experience:"ประสบการณ์",
	RM_jobTitle:"ชื่อตำแหน่ง",
	Open_org_Current_Cost:"ศูนย์ต้นทุนปัจจุบัน",
	RM004_06_comment_overallComment:"แสดงความคิดเห็น",
	RM_attachFile:"แนบไฟล์",
	RM_mobilePhone:"เบอร์มือถือ",
	RM_competencies:"ความสามารถ",
	RM_applicationHistory:"ประวัติการสมัคร / พิมพ์ใบสมัคร",
	RM_jobScope:"หน้าที่รับผิดชอบ",
	RM_sportSkill:"ทักษะทางกีฬา",
	RM_reward:"รางวัล",
	RM_noDrivinglicense:"เลขที่ใบอนุญาต",
	RM_soi:"ซอย",
	RM_walkIn:"สมัครด้วยตัวเองที่บริษัท",
	rl5_nav_Form:"รายงาน",
	RMProbation_cancel:"ยกเลิก",
	Open_org_Edu:"การศึกษา",
	Open_org_Job_code:"รหัสงาน",
	RM_retain:"รักษา",
	RM004_06_expectedProductivity:"ผลผลิตของงานที่คาดหวัง",
	employee_name_main:"ชื่อพนักงาน",
	modalPositionAdd_WT:"ประเภทงาน",
	approve_leave:"การอนุมัติ",
	navbar_gsb01_noMessage:"ไม่มีข้อความ",
	afternoon:"Afternoon",
	Open_org_Agefrom:"อายุตั้งแต่",
	Open_org_train:"การฝึกกอบรม",
	Open_org_Excel:"ส่งออกรูปแบบ Excel",
	Open_org_all:"ทั้งหมด",
	leave_type:"Leave Type",
	approve:"Approve",
	RM_faculty:"คณะ",
	career_empCode:"รหัสพนักงาน",
	RM004_06_evaluate_save:"บันทึก",
	career_idpYear:"ปีการประเมิน",
	RM_develop:"พัฒนา",
	RM_position:"ตำแหน่งที่สมัคร",
	RM_traningDetail:"กรุณาระบุหลักสูตรการฝึกอบรมที่ท่านเคยได้รับ (สามารถระบุมากกว่า 1 รายการ) / Please fill in your previous training program, if any (can add more than 1 record).",
	RM_referal:"พนักงานในบริษัท"
});
$translateProvider.translations('en', {
	RM_applyDate:"Apply Date",
	RM_honor:"Honor",
	Open_org_Export:"Export Type",
	RMProbation_agree:" Agree",
	probation:"",
	RMProbationHistory_Em_ID:"Employee",
	RM_phoneNumber:"Phone Number",
	attach_file:"",
	annual:"",
	tel:"",
	RM_weight:"Weight",
	Manage_Org_R2_NLC:"Node Level Code",
	career_curLevel:"Current Level",
	RM_otherSkill:"Other Skill",
	RM_monthlySalary:"Monthly salary",
	ApplicationBackend:"Backend",
	Open_org_Travel:"Travel",
	navbar_gsb01_inbox:"Inbox",
	personal:"",
	RM_hrAssistant_hrOfficer:"HR assistant / HR officer",
	RM004_06_evaluate_comment:"Comment",
	RM_careerDetail:"Experience of individual work To be successful. You need set of goal, plan and action for job and position",
	RM004_06_user_info_assesor:"Assessor",
	morning:"",
	RM_clusterOfRelate:"Cluster of relatedoccupations or finctions",
	RM_institute:"Institute",
	RMProbationHistory_No:"No.",
	overtime:"",
	RM:"mnknmkl",
	RM_careerPath:"Career Path",
	RM_attachDate:"Attach Date",
	RM_fatherName:"Father's name",
	RM_tel:"Tel.",
	RM_occupation:"Occupation ",
	RM_occupationType:"Occupation type",
	activity:"",
	without_pay:"",
	RM_floor:"Floor",
	Open_org_mael:"Male",
	ApplicationProbationEva:"Probation Evaluation",
	RM_language:"Language",
	RM_function:"Function",
	RM_date_of_birth:"Date of Birth",
	RM_country:"Country",
	RM_country_of_birth:"Country of birth",
	RM_delete:"Delete",
	RM004_06_evaluate_submit:"Submit",
	RM_to:"To",
	RM_computerSkill:"Computer Skill",
	RM004_06_user_info_department:"Department",
	ess_no:"No",
	RMProbationHistory_Department:"Department",
	RM004_06_evaluate_probationEvaluation:"Probation evaluation",
	benefit_leaveTpye:"Leave Type",
	career_supervisor:"Supervisor",
	RM_remark:"Remark",
	RM_jobDescription1:"Job Description for 1",
	RM_jobDescription2:"Job Description for 2",
	RM_street:"Street",
	RM004_06_evaluate_submitAnAssessment:"Submit an assessment",
	RM_cancel:"Cancel",
	RM_submit:"Submit",
	RM004_07_probation:"Probation",
	idp_kpi:"",
	RM_jobDescription3:"Job Description for 3",
	RM_lastName:"Last name",
	RM_jobDescription:"Job Description",
	RM_internet:"Internet",
	Open_org_Cost_center:"To Cost Center ",
	Open_org_vacancy_type:"Vacancy Type",
	RM_jobFamilyDetail:"Job family is collect a cluster of related occupations or functions. That point is competencies as Knowleadge, skilld and attributes. So bring out essence each of function for managing group Job family.",
	Manage_Org_R2_ION:"Indirect Org Node",
	RM_responsibility:"Responsibility",
	RM_career_path:"A career path is a sequence of jobs that leads to your short- and long-term career goals. Some follow a linear career path within one field, while others periodically",
	RM_disagree:"Disagree",
	org_sub_func:"Sub Function",
	RM_hrBusinessPartner:"HR Business Parrtner",
	reason_of_leave:"",
	RM_career:"Career",
	Manage_Org_R2_NewOrg:"New Organization Node",
	Open_org_Em_type:"Employee Type",
	RM_trainingProgram:"Training Program",
	RM_status:"Status",
	leave:"",
	RM_hrCoordinator:"HR Coordinator",
	Open_org_sub_func:"หกฟ",
	RM_upload:"Upload",
	RM_position_applied_for1:"Position Applied for 1 ",
	RM_position_applied_for2:"Position Applied for 2",
	RM_position_applied_for3:"Position Applied for 3",
	RM002_01_job_detail_Experience:"Work experience",
	RM_applicationForm:"Application Form",
	RM_id_card_for_FWD:"ID Card for FWD",
	RM_joinUs:"Join Us",
	RM_attributes:"Attributes",
	RM_age:"Age",
	Open_org_Qrganize:"Organization",
	RM_skills:"Skills",
	RMProbation_popup_success_completed:" has been completed.",
	RM_village:"Village",
	Open_org_Reason:"Reason",
	Manage_Org_R2_location:"Location",
	full_day:"",
	RM_workGoals:"Work Goals",
	ess_yes:"Yes",
	leave_request:"",
	RM_careerMangement:"Career Management",
	RM_prefix:"Prefix",
	RM_maritalStatus:"Marital Status",
	RMProbation_confirm:"Confirm",
	RM004_08_assessment_pass:"Pass ",
	RMProbationHistory_Name:"Name - Lastname",
	RM_view:"View",
	Open_org_Req_date:"Request Date",
	Open_org_Em_group:"Employee Group",
	training:"",
	RM_personalInFormation:"Personal Information",
	RM_room:"Room",
	Open_org_Job_func:"Job Function",
	RM_development:"Development",
	RM_educationBackground:"Education Background",
	RMProbationHistory_Position:"Position Title",
	Open_org_Pos_leval:"Position Level",
	RM_workExperience:"Work Experience",
	Open_org_CR:"Create Requisition",
	navbar_gsb01_lang:"EN",
	Open_org_Special:"Special request",
	modalPositionAdd_PB:"Position Title",
	RM_gender:"Gender",
	RM_profileImage:"Profile Image",
	RM_talent:"Talent",
	ticket:"",
	RM_district:"District",
	Open_org_PBC:"Position Box Code",
	Open_org_femael:"Female",
	RM_height:"Height",
	RM_careerManageDetail:"Career Management is system, process and plan by Humen resource team for supporting employee to retain, develop and Utilize yourself",
	Open_org_move_position:"Move Position Box",
	RM004_06_user_info_positionTitle:"Position Title",
	RM004_06_user_info_nameLastName:"Name-Last Name",
	Open_org_local_job:"Local Job Band",
	Open_org_gender:"Gender",
	RM_successfulWork:"Successful work",
	RM_couseName:"Couse Name",
	RM_motherName:"Mother's name",
	Open_org_Name:"Name",
	RM_emailAddress:"Email Address",
	RM_id_card_number:"ID card number",
	RM004_08_assessment_probation:"Probation",
	RM_submitApplication:"Submit application",
	RM004_08_assessment_days:"Days",
	RMProbation_popup_success_to:"to ",
	RM_edit:"Edit",
	delegate_work_to:"",
	RM004_06_attach_file_label:"Attach File",
	Manage_Org_R2_ED:"Effective Date",
	RM_vision:"Vision",
	RM_fullName_eng:"Full Name in English",
	requisition:"",
	RM_finish:"Finish",
	sick:"",
	resignReq_no:"No",
	RM_languageSkill:"Language Skill",
	ApplicationManageOrg:"Manage Organize Chart",
	RM004_06_evaluate_overallComment:"Overall comment",
	RM_pleaseSelect:"Please Select",
	Open_org_Abroad:"Go Abroad",
	resignReq_resignDate1:"Resign Date",
	RM_subDistrict:"Sub District",
	RM_level:"Level",
	RM_major:"Major",
	RM_verifyCode:"Verify Code",
	RM_jobApplicationDate:"Job Application Date",
	Open_org_move_to:"Move To",
	Open_org_Budget:"Budget",
	RM004_06_user_info_employeeID:"Employee ID",
	RM002_01_job_detail_Other:"Other details",
	RM_degree:"Degree",
	RM_printAdvertisement:"Print Advertisement",
	RM_job_deteil_major:"Major",
	Open_org_pos_box_type:"Position Box Type",
	RM_relationShip:"Relationship",
	RM_Salary:"Salary",
	RM004_06_user_info_days:"Days",
	employee_name_sub:"Employee Name",
	RM_id:"ID",
	RM004_06_user_info_period:"Period",
	RMProbation_close:"Close",
	other:"",
	RM004_08_assessment_notPass:"Not Pass ",
	go_abroad:"",
	RM_track_of_disabilities:"Track of Disabilities",
	RM004_06_user_info_hiringDate:"Hiring date",
	Open_org_Active:"Active",
	save:"",
	RM_fileName:"File Name ",
	RMProbationHistory_Eva:"Evaluate Date",
	RM_vicePresident_of_hr:"Vice President of HR",
	RM004_08_assessment_extend:"Extend",
	RM_building:"Building",
	delegate:"",
	RM_nationality:"Nationality",
	RM_otherInformation:"Other Information",
	resignReq_reOfResign:"Reason of Resign",
	RM_province:"Province",
	RM_headhunterRecruitAgency:"Headhunter Recruitment Agency",
	RM002_01_job_detail_Educational:"Educational qualification",
	RM_utlize:"Utlize",
	modalPositionAdd_NPB:"New Position Box",
	RM_driving_license_type:"Driving License Type",
	Manage_Org_R2_Dept:"Dept Code",
	Manage_Org_R2_Node_Type:"Node Type",
	urgent:"",
	half_day:"",
	RM_moo:"Moo",
	RM_educationLevel:"Education Level",
	ApplicationApp:"Application",
	RM_apply:"Apply",
	Manage_Org_R2_count:"Count",
	resignReq_exitlnter:"Exit lnterview",
	RM004_06_expected_product_overallComment:"Overall comment",
	RM_zipCode:"Zip Code",
	RM_jobFamily:"Job Family",
	Manage_Org_R2_ParentOrg:"Parent Org Node",
	RM004_06_attach_file_drag_drop:"Drag & Drop",
	inbox:"",
	date:"",
	resignReq_reqDate1:"Request Date",
	RM_sector_of_position1:"Sector of Position 1",
	RM_sector_of_position3:"Sector of Position 3",
	RM_sector_of_position2:"Sector of Position 2",
	Open_org_Request:"Request By",
	RM_personalDataProtect:"Personal Data Protection Policy",
	resignReq_status:"Status",
	RM_essence:"Essence",
	RM_grade:"Grade",
	RM_qualification:"Qualification",
	RM_weakness:"Weakness",
	RM_attachType:"Attach Type",
	Manage_Org_R2_cost:"Cost Center",
	RM_save:"Save",
	RM_strengths:"Strengths",
	resignReq_rejectReason:"Reject Reason",
	RM_information:"Information",
	RM_from:"From",
	RM004_06_user_info_passingDate:"Passing date",
	RM_which_channel_dyk:"Which channel did you know the job vacancy of the company ? ",
	workflow:"",
	RM_musicSkill:"Music Skill",
	RM_companyName:"Company name",
	expense:"",
	Open_org_report:"report type",
	RM_back_to_home:"Back to Home",
	RM_address:"Address",
	hourly:"",
	request:"",
	RM_militaryStatus:"Military Status",
	RM_other:"Other",
	Manage_Org_R2_NED:"Node Effective Date",
	RM_humanResource:"Human Resource",
	RM_experience:"Experience",
	RM_jobTitle:"Job title",
	Open_org_Current_Cost:"Current Cost Center",
	RM004_06_comment_overallComment:"Overall comment",
	RM_attachFile:"Attach File",
	RM_mobilePhone:"Mobile Phone",
	RM_competencies:"Competencies",
	RM_applicationHistory:"Application History / Print",
	RM_jobScope:"Job Scope",
	RM_sportSkill:"Sport Skill",
	RM_reward:"Reward",
	RM_noDrivinglicense:"No. Driving License",
	RM_soi:"Soi",
	RM_walkIn:"Walk-in",
	rl5_nav_Form:"Form",
	RMProbation_cancel:"Cancel",
	Open_org_Edu:"Education",
	Open_org_Job_code:"Job code",
	RM_retain:"Retain",
	RM004_06_expectedProductivity:"Expected Productivity",
	employee_name_main:"Employee Name",
	modalPositionAdd_WT:"Work Type",
	approve_leave:"My Leave Approve",
	navbar_gsb01_noMessage:"No message",
	afternoon:"",
	Open_org_Agefrom:"Age From",
	Open_org_train:"Training ",
	Open_org_Excel:"Export Excel",
	Open_org_all:"All",
	leave_type:"",
	approve:"",
	RM_faculty:"Faculty",
	career_empCode:"Employee Code",
	RM004_06_evaluate_save:"Save",
	career_idpYear:"IDP Year",
	RM_develop:"Develop",
	RM_position:"Position",
	RM_traningDetail:"as Toeic report, Non-Life Broker Licenses, Life Insurance Broker License and etc.",
	RM_referal:"Referal"
});
$translateProvider.preferredLanguage('th');
$translateProvider.useLocalStorage();
}]);